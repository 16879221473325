import useWindowDispatchOnLoad from '@concur/core-ui-shell/lib/utils/useWindowDispatchOnLoad';
import useWindowEventCallback from '@concur/core-ui-shell/lib/utils/useWindowEventCallback';
import { useState } from 'react';

const useEmbeddedApp = ({
    fireOnContainerLoaded,
    listenForEmbeddedAppLoaded,
    onLoad,
    fireOnContainerReady,
    appData = {},
}) => {
    const [loaded, setLoaded] = useState(false);

    const containerRef = useWindowDispatchOnLoad(fireOnContainerLoaded);

    useWindowEventCallback(listenForEmbeddedAppLoaded, () => {
        setLoaded(true);
        onLoad?.(true);
    });

    const attachToRef = useWindowDispatchOnLoad(fireOnContainerReady, appData);

    return {
        loaded,
        containerRef,
        attachToRef,
    };
};

export default useEmbeddedApp;
