import withErrorBoundary from '@concur/core-ui-shell/lib/hoc/withErrorBoundary';
import compose from '@concur/core-ui-shell/lib/utils/compose';
import { withFormatter } from '@concur/nui-intl-runtime';
import classnamesBind from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import Logo from './Logo';

const NavLogo = ({
    styles,
    className,
    isRetiredBrand,
    cssBlock,
    descriptionId,
    formatter,
}) => {
    let logoDescriptionMessageId = 'CoreUI.SAPConcurLogo';
    if (isRetiredBrand) {
        logoDescriptionMessageId = 'CoreUI.ConcurLogo';
    }
    const classnames = classnamesBind.bind(styles);
    return (
        <>
            <Logo
                styles={styles}
                cssBlock={cssBlock}
                className={className}
                isRetiredBrand={isRetiredBrand}
                hideTitle
            />
            <span
                id={descriptionId}
                className={classnames(
                    `${cssBlock}__screen-reader-only`,
                )}
            >
                {formatter.formattedMessage({ id: logoDescriptionMessageId })}
            </span>
        </>
    );
};

NavLogo.displayName = 'NavLogo';

NavLogo.propTypes = {
    descriptionId: PropTypes.string,
    className: PropTypes.string,
    cssBlock: PropTypes.string,
    isRetiredBrand: PropTypes.bool,
    styles: PropTypes.object,
};

export default compose(
    withFormatter,
    withErrorBoundary,
)(NavLogo);
