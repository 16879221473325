import React from 'react';
import {
    withDebugMode, withStylesLoaded, compose,
} from '@concur/core-ui-shell';
import IdleTimer from './_IdleTimer';
import withNuiIntl from '../utils/withNuiIntl';
import withStylesPlaceholder from '../utils/withStylesPlaceholder';

const DEBUG_COOKIE_NAME = 'idle_timer_debug';
const DEBUG_COOKIE_MAX_AGE = 7200; // 2 hours

// this wrapper component is necessary to wrap the component
// in a ThemeProvider (handled in withStylesLoaded)
const AppIdleTimer = (props) => <IdleTimer {...props} showError={() => false} />;

AppIdleTimer.displayName = 'AppIdleTimer';

AppIdleTimer.propTypes = {
    ...IdleTimer.propTypes,
};

export default compose(
    withDebugMode(DEBUG_COOKIE_NAME, DEBUG_COOKIE_MAX_AGE),
    withStylesPlaceholder,
    withStylesLoaded,
    withNuiIntl,
)(AppIdleTimer);
