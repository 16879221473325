import ScreenReaderMessage from '@concur/nui-widgets/ScreenReaderMessage';
import React, { useEffect, useState } from 'react';

const ScreenReaderMessageQueue = ({ announcement }) => {
    const [announceText, setAnnounceText] = useState('');

    useEffect(() => {
        const announcementTimer = setTimeout(() => {
            if (announcement?.trim()) {
                setAnnounceText(announcement);
            }
        }, 1000);
        return () => {
            clearTimeout(announcementTimer);
        };
    }, [announcement]);

    return (
        <ScreenReaderMessage>
            {announceText}
        </ScreenReaderMessage>
    );
};

export default ScreenReaderMessageQueue;
