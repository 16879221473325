import React from 'react';

const SignOutSvgIcon = ({ pathClassName, ...props }) => (
    <svg
        {...props}
        aria-hidden
        height={24}
        viewBox="0 0 18 18"
        version="1.1"
        width={24}
        x="0px"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        xmlSpace="preserve"
        y="0px"
    >
        <path
            className={pathClassName}
            d="M8.21248 10.2383C7.89393 10.2383 7.63226 10.1359 7.42748 9.93115C7.2227 9.72637 7.12031 9.4647 7.12031 9.14615V1.50092C7.12031 1.20512 7.2227 0.954827 7.42748 0.750044C7.63226 0.522507 7.89393 0.408739 8.21248 0.408739C8.50828 0.408739 8.75857 0.522507 8.96335 0.750044C9.19089 0.954827 9.30466 1.20512 9.30466 1.50092V9.14615C9.30466 9.4647 9.19089 9.72637 8.96335 9.93115C8.75857 10.1359 8.50828 10.2383 8.21248 10.2383ZM11.489 3.34396C12.786 3.95831 13.8326 4.87984 14.629 6.10853C15.4481 7.33723 15.8577 8.71383 15.8577 10.2383C15.8577 11.3077 15.6529 12.3089 15.2434 13.2418C14.8566 14.152 14.3105 14.9597 13.6051 15.6651C12.9225 16.3477 12.1147 16.8824 11.1818 17.2692C10.2489 17.6788 9.25915 17.8836 8.21248 17.8836C7.14306 17.8836 6.1419 17.6788 5.209 17.2692C4.29885 16.8824 3.4911 16.3477 2.78573 15.6651C2.10312 14.9597 1.55703 14.152 1.14747 13.2418C0.760655 12.3089 0.567248 11.3077 0.567248 10.2383C0.567248 8.71383 0.965438 7.33723 1.76182 6.10853C2.58095 4.87984 3.63899 3.95831 4.93595 3.34396V4.57266C3.95755 5.1415 3.16117 5.9265 2.54682 6.92767C1.95522 7.90607 1.65942 9.00963 1.65942 10.2383C1.65942 11.1485 1.83008 12.0017 2.17138 12.7981C2.51269 13.5945 2.97914 14.2885 3.57073 14.8801C4.16233 15.4717 4.85632 15.9381 5.65269 16.2794C6.44907 16.6207 7.30233 16.7914 8.21248 16.7914C9.12263 16.7914 9.97589 16.6207 10.7723 16.2794C11.5686 15.9381 12.2626 15.4717 12.8542 14.8801C13.4458 14.2885 13.9123 13.5945 14.2536 12.7981C14.5949 12.0017 14.7655 11.1485 14.7655 10.2383C14.7655 9.00963 14.4584 7.90607 13.844 6.92767C13.2524 5.9265 12.4674 5.1415 11.489 4.57266V3.34396Z"
        />
    </svg>
);

export default SignOutSvgIcon;
