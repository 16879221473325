import React from 'react';
import PropTypes from 'prop-types';

const Navigation = React.forwardRef((props, ref) => {
    const {
        ariaLabel,
        className,
        children,
        ...otherProps
    } = props;

    return (
        <nav
            {...otherProps}
            aria-label={ariaLabel}
            className={className}
            ref={ref}
        >
            {children}
        </nav>
    );
});

Navigation.displayName = 'Navigation';

Navigation.propTypes = {
    ariaLabel: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.node,
};

export default Navigation;
