import React from 'react';
import PropTypes from 'prop-types';
import classnamesBind from 'classnames/bind';
import Link from '@concur/nui-widgets/Link';
import FooterText from './_FooterText';

const ChinaICP = ({ cssBlock, styles }) => {
    const classnames = classnamesBind.bind(styles);

    return (
        <FooterText
            center
            styles={styles}
            cssBlock={cssBlock}
        >
            中华人民共和国增值电信业务经营许可证：合字B2-20130003
            <br />
            中数通信息有限公司 网站备案号：
            <Link
                className={classnames(`${cssBlock}__link`)}
                href="http://beian.miit.gov.cn"
                rel="noreferrer noopener"
                target="_blank"
                data-analytics-json={'{"applicationName":"footer","elementPath":"china-data-center-information","action":"click","elementType":"link"}'}
            >
                粤B2-20040246
            </Link>
        </FooterText>
    );
};

ChinaICP.displayName = 'ChinaICP';

ChinaICP.propTypes = {
    cssBlock: PropTypes.string,
    styles: PropTypes.object,
};

export default ChinaICP;
