import React from 'react';
import parseHtml, { domToReact } from 'html-react-parser';

import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import {
    ACTING_AS_OPTIONS,
    ENDPOINT_URLS,
} from '../constants';

const multipleSpacesRegex = /\s\s+/g;

const getItemDisplayText = (item) => {
    if (!item.Display) {
        return '';
    }
    const tempSpan = document.createElement('span');
    tempSpan.innerHTML = item.Display;
    return tempSpan.textContent
        .replace(String.fromCharCode(160), ' ') // &nbsp;
        .replace(multipleSpacesRegex, ' ');
};

const getItemText = (item) => {
    if (!item.Text) {
        return '';
    }
    return item.Text;
};

export const getClientSideSearchResults = (searchText, searchResults, selectedOption) => {
    const cachedSearchResults = searchResults[selectedOption];
    if (!cachedSearchResults) {
        return [];
    }
    const lowerCaseSearch = searchText.toLowerCase();
    return Object.keys(cachedSearchResults)
        .filter((protectedId) => (cachedSearchResults?.[protectedId]))
        .map((protectedId) => cachedSearchResults[protectedId])
        .filter((item) => lowerCaseSearch === ''
            || getItemText(item).toLowerCase().includes(lowerCaseSearch)
            || getItemDisplayText(item).toLowerCase().includes(lowerCaseSearch));
};

const parseOptions = (cssBlock, classnames) => ({
    replace: (domNode) => {
        const {
            attribs,
            children,
            name,
            type,
        } = domNode;

        if (type === 'tag' && name === 'div' && attribs?.class === 'cnqr-user-full-name') {
            return (
                <div
                    className={classnames(`${cssBlock}__item-user-name`, 'cnqr-user-full-name')}
                    data-test="cnqr-user-full-name"
                >
                    {domToReact(children, parseOptions)}
                </div>
            );
        }

        if (type === 'tag' && name === 'div' && attribs?.class === 'cnqr-user-additional-details') {
            return (
                <div className={classnames(`${cssBlock}__item-user-details`, 'cnqr-user-additional-details')}>
                    {domToReact(children, parseOptions)}
                </div>
            );
        }

        return domNode;
    },
});

export const listItemRenderer = (itemData, cssBlock, classnames) => parseHtml(
    itemData.Display || '',
    parseOptions(cssBlock, classnames),
);

export const startSessionPost = async (selectedOption, selectedUser, isGov, govAllChecked) => {
    const tmpCorrelationId = uuidv4();

    const govParams = isGov ? {
        incTavsAll: selectedOption === ACTING_AS_OPTIONS.GOV_ALL_USERS,
        incTavsArrangedByMe: selectedOption === ACTING_AS_OPTIONS.GOV_ALL_USERS && govAllChecked,
    } : {};

    try {
        const response = await axios.post(ENDPOINT_URLS.ACTING_AS_START_SESSION, {
            incDelegatees: selectedOption === ACTING_AS_OPTIONS.DELEGATE,
            incProxies: selectedOption === ACTING_AS_OPTIONS.PROXY,
            incSelfAssign: selectedOption === ACTING_AS_OPTIONS.SELF_ASSIGN,
            ...govParams,
            userId: selectedUser,
        }, {
            headers: {
                'concur-correlationid': tmpCorrelationId,
                'Referrer-Policy': 'strict-origin-when-cross-origin',
                Referer: window?.location?.href,
            },
            params: {
                href: window?.location?.href,
            },
        });

        return { response, tmpCorrelationId };
    } catch (sessionError) {
        return { sessionError, tmpCorrelationId };
    }
};

export const endSessionPost = async () => {
    const tmpCorrelationId = uuidv4();

    try {
        const response = await axios.post(ENDPOINT_URLS.ACTING_AS_END_SESSION, {
            noRedirect: true,
        }, {
            headers: {
                'concur-correlationid': tmpCorrelationId,
                'Referrer-Policy': 'strict-origin-when-cross-origin',
                Referer: window?.location?.href,
            },
            params: {
                href: window?.location?.href,
            },
        });

        return { response, tmpCorrelationId };
    } catch (sessionError) {
        return { sessionError, tmpCorrelationId };
    }
};
