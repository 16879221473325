/* eslint-disable no-unused-expressions */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnamesBind from 'classnames/bind';
import useUniqueId from '@concur/nui-widgets/lib/utils/useUniqueId';
import Button from '@concur/nui-widgets/Button';
import Icon from '@concur/nui-widgets/Icon';
import Overlay from '@concur/nui-widgets/Overlay';
import Tooltip from '@concur/nui-widgets/Tooltip';

const MenuItemToggle = React.forwardRef((props, ref) => {
    const {
        buttonAriaLabel,
        buttonClassName,
        buttonProps,
        children,
        className,
        styles,
        content,
        cssBlock,
        hideCaret,
        onClick,
        onClose,
        overlayProps,
        popperPlacement,
        tooltipMessage,
        iconOnly,
    } = props;

    const classnames = classnamesBind.bind(styles);
    const [expanded, setExpanded] = useState(false);

    const buttonId = useUniqueId();

    const handleToggleClick = (e) => {
        setExpanded(!expanded);
        onClick(e, !expanded);
    };

    const handleClose = () => {
        setExpanded(false);
        onClose();
        document.getElementById(buttonId)?.focus();
    };

    const classes = classnames(`${cssBlock}__listitem`, className);

    const getButtonContent = (additionalProps) => (
        <Button
            {...additionalProps}
            {...buttonProps}
            aria-label={buttonAriaLabel}
            className={classnames(
                `${cssBlock}__button`,
                buttonClassName,
                {
                    [`${cssBlock}__button--icon`]: iconOnly,
                    [`${cssBlock}__button--active`]: expanded,
                },
            )}
            id={buttonId}
            innerTextClasses={iconOnly ? classnames(`${cssBlock}__button-inner-text`) : undefined}
            onClick={handleToggleClick}
            role="menuitem"
        >
            {content}
            {!hideCaret && (
                <Icon
                    ariaHidden
                    iconName="arrow-2-s"
                    forceSize="xs-plus"
                />
            )}
        </Button>
    );

    return (
        <li className={classes} ref={ref}>
            <Overlay
                {...overlayProps}
                disableEdgeDetection
                onHide={handleClose}
                onShow={() => setExpanded(true)}
                popperPlacement={popperPlacement}
                show={expanded}
                toggleOverlay
                renderTargetComponent={
                    (popperTargetRef, referenceProps, targetClasses, targetProps) => {
                        const buttonContent = getButtonContent({
                            ...referenceProps,
                            ...targetProps,
                            ref: popperTargetRef,
                        });
                        return (
                            tooltipMessage && !expanded ? (
                                <Tooltip
                                    delay={1000}
                                    ref={popperTargetRef}
                                    placement={['bottom', 'top', 'right', 'left']}
                                    message={tooltipMessage}
                                    outerPopperProps={{
                                        showArrow: false,
                                        offset: 3,
                                    }}
                                    innerContainerProps={{
                                        className: classnames(`${cssBlock}__tooltip-inner`, className),
                                    }}
                                >
                                    {buttonContent}
                                </Tooltip>
                            ) : buttonContent

                        );
                    }
                }
                targetClassName={classnames(`${cssBlock}__target`)}
            >
                {children}
            </Overlay>
        </li>
    );
});

MenuItemToggle.displayName = 'MenuItemToggle';

MenuItemToggle.propTypes = {
    buttonAriaLabel: PropTypes.string,
    buttonClassName: PropTypes.string,
    buttonProps: PropTypes.object,
    children: PropTypes.node,
    className: PropTypes.string,
    content: PropTypes.node,
    onClick: PropTypes.func,
    onClose: PropTypes.func,
    overlayProps: PropTypes.object,
    cssBlock: PropTypes.string,
    hideCaret: PropTypes.bool,
    popperPlacement: PropTypes.string,
    iconOnly: PropTypes.bool,
    styles: PropTypes.object,
    tooltipMessage: PropTypes.string,
};

MenuItemToggle.defaultProps = {
    onClick: () => {},
    onClose: () => {},
    popperPlacement: 'bottom-start',
};

export default MenuItemToggle;
