/* eslint-disable consistent-return */
/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, {
    useState, useLayoutEffect, useRef, useEffect,
} from 'react';
import keycode from 'keycode';
import PropTypes from 'prop-types';
import { withFormatter } from '@concur/nui-intl-runtime';
import { lowerCase } from '@concur/core-ui-shell';

const Menu = (props) => {
    const {
        appHeaderWidth,
        ariaLabel,
        children,
        collapsible,
        parentMenuCssBlock,
        formatter,
        id,
        isFioriProductMenu,
        isTopLevelMenu,
        utilityWidth,
        ...otherProps
    } = props;

    const [overflowItems, setOverflowItems] = useState([]);
    const [listItemWidth, setListItemWidth] = useState(new Map());
    const [focusableMenuItems, setFocusableMenuItems] = useState([]);
    const [activeItem, setActiveItem] = useState();
    const focusableItemSelector = 'a, button, span[tabindex="0"], #helpPortalMenu';

    const listRef = useRef(null);
    const setRefs = useRef(new Map()).current;

    const getAllMenuItems = () => {
        const menu = document.querySelector(`[class*="${parentMenuCssBlock}"]`);

        if (!menu) {
            return [];
        }

        return Array.prototype.slice.call(menu.querySelectorAll('a'));
    };

    const detectWrap = () => {
        const wrappedItems = [];

        // 80 accounting for hamburger menu space
        let menuWidth = 80;
        // hardcoded 32 to account for additional padding
        const availableWidth = utilityWidth ? appHeaderWidth - utilityWidth - 32 : appHeaderWidth;

        React.Children.forEach(children, (child) => {
            // do not count overflow or dashboard as they are counted in availableWidth
            if (!child || child.key === 'overflowPrimary' || child.key === 'overflowSecondary') return;

            const listItemDimensions = setRefs.get(child.key)
                ? setRefs.get(child.key).getBoundingClientRect() : 0;

            // we're getting the random right side blowout due to listItemDimesions.width = 0
            // when the item was previously hidden, but we do want to count it for the next round
            // so we're setting state to keep the value if it's ever larger than 0
            if (listItemDimensions.width > 0) {
                setListItemWidth(listItemWidth.set(child.key, listItemDimensions.width));
            }

            if (listItemWidth.get(child.key)) {
                menuWidth += listItemWidth.get(child.key);
            }
            // do not wrap dashboard (logo)
            if (availableWidth <= menuWidth && child.key !== 'dashboard') {
                wrappedItems.push(child);
            }
        });
        return wrappedItems;
    };

    const getFocusableItems = (listItems) => {
        const focusableItems = [];
        listItems.forEach((item) => {
            const itemVisible = item.getAttribute('aria-hidden') !== 'true';
            const focusableItem = item.querySelector(focusableItemSelector);
            itemVisible && focusableItem
                && focusableItems.push(focusableItem);
        });

        return focusableItems;
    };

    // returns all currently focused item's submenu items
    const getFocusableSubMenuItems = () => {
        const focusedElement = document.activeElement;

        if (!focusedElement || !focusedElement.nextSibling) {
            return [];
        }

        const siblingMenu = focusedElement.nextSibling.children;
        // return the active element's sibling <ul>'s children
        return siblingMenu?.length
            ? getFocusableItems(Array.prototype.slice.call(siblingMenu))
            : [];
    };

    // returns all currently focused item's parent menu's items
    const getFocusableParentMenuItems = () => {
        // make sure we aren't in a dialog or on the menu item that has
        // triggered a dialog.  if so, that no longer obeys the
        // default menu keyboard handling
        let isDialog = false;
        let innerMenu;
        let currentElement = document.activeElement;
        if (currentElement.getAttribute('aria-haspopup') !== 'dialog'
            || currentElement.getAttribute('aria-expanded') !== 'true') {
            // current element does not have a popup or popup is not expanded
            // now check if current element is within a dialog
            while (currentElement && currentElement.getAttribute('role') !== 'dialog') {
                currentElement = currentElement.parentElement;
            }
            if (currentElement) {
                // if in a dialog, check if there is an inner menu to traverse
                isDialog = true;
                innerMenu = currentElement.querySelector('[class*="submenu"]');
            }
        } else {
            isDialog = true;
        }

        const activeItemParent = isDialog ? innerMenu : listRef?.current;

        if (!activeItemParent) {
            return [];
        }

        const parentMenuChildren = activeItemParent.children;
        // return the active element's parent <li>'s parent <ul>'s children
        return parentMenuChildren.length
            ? getFocusableItems(Array.prototype.slice.call(parentMenuChildren)) : [];
    };

    const hasParentNav = () => {
        const ul = listRef?.current;

        return ul?.parentElement?.tagName === 'NAV';
    };

    useEffect(() => {
        const menuItems = isFioriProductMenu ? getAllMenuItems() : getFocusableSubMenuItems();
        switch (activeItem) {
        case null: // focus already set after closing menu; no action needed
            break;

        default:
            if (menuItems) {
                menuItems[0]?.focus();
            }
            break;
        }
    }, [activeItem]);

    // synchronous - prevent visually update until effect is complete, reducing flickering
    // limited to run only when appHeaderWidth or utilityWidth changes
    useLayoutEffect(() => {
        // only run detectWrap on collapsible Menus
        if (collapsible && appHeaderWidth > 0) {
            setOverflowItems(detectWrap());
        }
    }, [appHeaderWidth, utilityWidth]);

    const onSetActiveItem = (item) => {
        React.Children.forEach(children, (child) => {
            const childRef = setRefs.get(child?.key);
            if (childRef?.contains?.(item)) {
                // if contains submenu
                if (item?.nextSibling) {
                    setActiveItem(child?.key);
                }
            }
        });
    };

    const focusDown = (e) => {
        const menuItems = isFioriProductMenu ? getAllMenuItems() : getFocusableParentMenuItems();
        if (menuItems) {
            const activeIndex = menuItems.indexOf(document.activeElement);
            // focus on next item in list
            if ((activeIndex !== menuItems.length - 1) && activeIndex !== -1) {
                menuItems[activeIndex + 1]?.focus();
            // after reaching the end of the items, focus on first item
            } else {
                menuItems[0]?.focus();
            }
        }
        e.stopPropagation();
    };

    const focusUp = (e) => {
        const menuItems = isFioriProductMenu ? getAllMenuItems() : getFocusableParentMenuItems();
        if (menuItems) {
            const activeIndex = menuItems.indexOf(document.activeElement);
            // focus on last item in list
            if (activeIndex <= 0) {
                menuItems[menuItems.length - 1]?.focus();
            // focus on previous item
            } else {
                menuItems[activeIndex - 1]?.focus();
            }
        }
        e.stopPropagation();
    };

    const closeMenu = (e) => {
        if (activeItem) {
            setActiveItem(null);
            const parentMenuItem = document
                .activeElement?.parentElement?.parentElement?.parentElement?.children[0];
            parentMenuItem?.focus();
            e.stopPropagation();
        }
    };

    const handleFocus = () => {
        if (!focusableMenuItems || focusableMenuItems.length < 1) {
            const menuItems = isFioriProductMenu
                ? getAllMenuItems() : getFocusableParentMenuItems();
            setFocusableMenuItems(menuItems);
        }
    };

    const handleKeyDown = (e) => {
        switch (e.keyCode) {
        case keycode.codes.esc:
            closeMenu(e);
            break;
        case keycode.codes.tab:
            // do not focus trap when tabbing through top-level menu items
            if (!isTopLevelMenu && !hasParentNav()) {
                e.preventDefault();
                if (e.shiftKey) {
                    focusUp(e);
                } else {
                    focusDown(e);
                }
            }
            break;
        case keycode.codes.down:
        case keycode.codes.right:
            e.preventDefault();
            focusDown(e);
            break;
        case keycode.codes.up:
        case keycode.codes.left:
            e.preventDefault();
            focusUp(e);
            break;
        default:
            break;
        }
    };

    const handleMouseEnter = (e) => {
        if (!isFioriProductMenu) {
            onSetActiveItem(e.target);
        }
    };

    const handleMouseLeave = (e) => {
        if (!isFioriProductMenu) {
            closeMenu(e);
        }
    };

    const triggerClick = (e) => {
        // one special case: don't let the menu intercept the space key
        // for checkboxes
        if (e.keyCode === keycode.codes.space
            && document.activeElement?.tagName === 'INPUT'
            && document.activeElement?.getAttribute('type') === 'checkbox') {
            e?.target?.click();
            return;
        }
        // open sub-submenu, focus on first element
        if (document.activeElement?.nextSibling) {
            onSetActiveItem(document.activeElement);
        // trigger click on item
        } else {
            e?.target?.click();
        }
    };

    const renderChildren = (subItems) => React.Children.map(children, (child) => {
        if (!child) return;

        const childProps = {
            expanded: activeItem === child?.key,
            onClick: (e) => {
                e.preventDefault();
                triggerClick(e);
            },
            onKeyDown: (e) => {
                if ((e.keyCode === keycode.codes.enter
                    || e.keyCode === keycode.codes.space)) {
                    e.preventDefault();
                    triggerClick(e);
                }
            },
            onMouseEnter: handleMouseEnter,
            onMouseLeave: handleMouseLeave,
        };

        // fragments need to be traversed manually
        if (child?.key?.includes('fragment')) {
            return React.cloneElement(
                child,
                {
                    ...child.props,
                    isFioriProductMenu,
                },
                React.Children.map(child.props.children, (fragmentChild) => {
                    if (fragmentChild) {
                        return React.cloneElement(fragmentChild, {
                            ...childProps,
                            ref: (node) => (
                                !node ? setRefs.delete(child.key) : setRefs.set(child.key, node)
                            ),
                            isHidden: subItems.some((item) => item.key === child.key),
                            isFioriProductMenu,
                            parentMenuCssBlock,
                        });
                    }
                }),
            );
        }

        // more MenuItem
        if (child.props.isOverflow) {
            return React.cloneElement(child, {
                ...childProps,
                formatter,
                hasPopover: true,
                isFioriProductMenu,
                isHidden: subItems.length === 0,
                ref: (node) => (!node ? setRefs.delete(child.key) : setRefs.set(child.key, node)),
                subItems,
                parentMenuCssBlock,
            });
        }
        // add ref based on child key to other MenuItems
        // hide or show based on presence in subItems
        return React.cloneElement(child, {
            ...childProps,
            ref: (node) => (!node ? setRefs.delete(child.key) : setRefs.set(child.key, node)),
            isHidden: subItems.some((item) => item.key === child.key),
            isFioriProductMenu,
            parentMenuCssBlock,
        });
    });

    return (
        <ul
            {...otherProps}
            aria-label={ariaLabel}
            data-test={id ? `menu__list-${lowerCase(id)}` : null}
            onFocus={handleFocus}
            onKeyDown={handleKeyDown}
            ref={listRef}
            role="menubar"
        >
            {renderChildren(overflowItems)}
        </ul>
    );
};

Menu.displayName = 'Menu';

Menu.propTypes = {
    appHeaderWidth: PropTypes.number,
    ariaLabel: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.node,
    collapsible: PropTypes.bool,
    id: PropTypes.string,
    isFioriProductMenu: PropTypes.bool,
    isTopLevelMenu: PropTypes.bool,
    parentMenuCssBlock: PropTypes.string,
    utilityWidth: PropTypes.number,
};

export default withFormatter(Menu);
