import classnamesBind from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { withFormatter } from '@concur/nui-intl-runtime';
import { compose, withErrorBoundary } from '@concur/core-ui-shell';
import SiteBanner from './_SiteBanner';
import styles from './AppBanner.css';

const CSS_BLOCK = 'sapcnqr-app-banner';

const Banner = (props) => {
    const {
        formatter,
        userPrefs,
        /* eslint-disable-next-line */
        useFullWidth,
        useSmallViewportBreakpoints,
        showHomepageBanner,
    } = props;

    const classnames = classnamesBind.bind(styles);

    const fixedBannersRef = useRef();
    const testEntityBannerPlaceholder = useRef();
    const testEntityBannerContainer = useRef();
    const relativeBannersRef = useRef();

    const hasRelativeBanners = () => userPrefs?.isTestUser || showHomepageBanner;

    const setPlaceholderHeight = (placeholder, container) => {
        if (placeholder && container) {
            // eslint-disable-next-line no-param-reassign
            placeholder.style.height = `${container.offsetHeight}px`;
        }
    };

    // this header container and placeholder is drawn by the shell
    const getAncestralHeaderContainerEl = () => document?.getElementById('sapCnqrHeaderContainer');
    const getAncestralPlaceholderEl = () => document?.getElementById('sapCnqrHeaderPlaceholder');

    const updatePlaceholderHeights = () => {
        if (userPrefs?.isTestEntity) {
            setPlaceholderHeight(
                testEntityBannerPlaceholder?.current,
                testEntityBannerContainer?.current,
            );
        }
        setPlaceholderHeight(
            getAncestralPlaceholderEl(),
            getAncestralHeaderContainerEl(),
        );
    };

    const updateHeaderPosition = () => {
        updatePlaceholderHeights();
        const ancestralHeader = getAncestralHeaderContainerEl();
        if (ancestralHeader) {
            ancestralHeader.style.top = '0px';

            if (userPrefs?.isTestEntity && fixedBannersRef?.current) {
                ancestralHeader.style.top = `${fixedBannersRef.current.offsetHeight}px`;
            }
        }
    };

    const moveFixedBanner = () => {
        const ancestralHeader = getAncestralHeaderContainerEl();
        if (ancestralHeader && userPrefs?.isTestEntity && fixedBannersRef?.current) {
            const gramps = ancestralHeader.parentElement;
            gramps.insertBefore(fixedBannersRef.current, gramps.firstChild);
        }
        updateHeaderPosition();
    };

    const handleScroll = () => {
        const relativeBannersEl = relativeBannersRef?.current;
        const ancestralHeader = getAncestralHeaderContainerEl();
        if (hasRelativeBanners() && relativeBannersEl && ancestralHeader) {
            let scrollBuffer = 0;
            if (userPrefs?.isTestEntity && fixedBannersRef?.current) {
                scrollBuffer = fixedBannersRef.current.offsetHeight;
            }
            const windowScroll = window?.scrollY || window?.pageYOffset;
            if (windowScroll < relativeBannersEl.offsetHeight) {
                ancestralHeader.style.top = `${scrollBuffer - windowScroll}px`;
            } else {
                ancestralHeader.style.top = `${scrollBuffer - relativeBannersEl.offsetHeight}px`;
            }
        }
    };

    const handleLoad = () => {
        updatePlaceholderHeights();
        if (hasRelativeBanners()) {
            setTimeout(() => {
                moveFixedBanner();
            }, 0);
        }
    };

    const handleResize = () => {
        updatePlaceholderHeights();
        handleScroll();
    };

    const handleUpdatePlaceholderHeights = () => {
        updatePlaceholderHeights();
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        window.addEventListener('load', handleLoad);
        window.addEventListener('scroll', handleScroll);
        window.addEventListener('updatePlaceholderHeights', handleUpdatePlaceholderHeights);
        return () => {
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('load', handleLoad);
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('updatePlaceholderHeights', handleUpdatePlaceholderHeights);
        };
    });

    useEffect(() => {
        handleScroll();
        updateHeaderPosition();
        setTimeout(() => {
            moveFixedBanner();
        }, 0);
    });

    const classes = classnames(
        CSS_BLOCK,
        {
            [`${CSS_BLOCK}--fully-responsive`]: useSmallViewportBreakpoints,
            [`${CSS_BLOCK}--test-user`]: userPrefs?.isTestUser,
        },
    );

    return (
        <>
            <div className={classes} ref={fixedBannersRef}>
                {
                    userPrefs?.isTestEntity
                    && (
                        <>
                            <SiteBanner
                                data-test="test-entity-site-banner"
                                accentColor={4}
                                iconName="gear"
                                ref={testEntityBannerContainer}
                                sticky
                                stickyFrame
                                text={formatter.formattedMessage({ id: 'CoreUI.testEntityBannerText' })}
                            />
                            <div
                                ref={testEntityBannerPlaceholder}
                                className={classnames(`${CSS_BLOCK}__test-entity-placeholder`)}
                            />
                        </>
                    )
                }
            </div>
            <div className={classes} ref={relativeBannersRef}>
                {userPrefs?.isTestUser
                    && (
                        <SiteBanner
                            data-test="test-user-site-banner"
                            accentColor={9}
                            iconName="user"
                            text={formatter.formattedMessage({ id: 'CoreUI.testUserBannerText' })}
                        />
                    )}
                {showHomepageBanner
                    && (
                        <div id="homepage-rr-banner" />
                    )}
            </div>
        </>
    );
};

Banner.displayName = 'Banner';

Banner.cssBlock = CSS_BLOCK;

Banner.propTypes = {
    userPrefs: PropTypes.object,
    useFullWidth: PropTypes.bool,
    useSmallViewportBreakpoints: PropTypes.bool,
    showHomepageBanner: PropTypes.bool,
};

export default compose(
    withFormatter,
    withErrorBoundary,
)(Banner);
