import NavMenu from '@concur/nui-widgets/NavMenu';
import classnamesBind from 'classnames/bind';
import Icon from '@concur/nui-widgets/Icon';
import { languageDetails, withFormatter } from '@concur/nui-intl-runtime';
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import AppHeaderContext from './_AppHeaderContext';

const LanguageDropdown = (props) => {
    const {
        cssBlock,
        styles,
        formatter,
    } = props;

    const classnames = classnamesBind.bind(styles);

    const {
        isMayFourth,
    } = useContext(AppHeaderContext) || {};

    const getLangHref = (lang) => {
        const query = queryString.parse(window.location.search);

        const queryWithLang = {
            ...query,
            lang,
        };

        return `${window.location.pathname}?${queryString.stringify(queryWithLang)}`;
    };

    // Generates the list for Tier 1 Language dropdown on nui-signin
    const languageButtonMenuItems = () => Object.keys(languageDetails)
        .filter((lang) => languageDetails[lang].tier === 1)
        .map((lang) => (
            <NavMenu.Item
                key={lang}
                href={getLangHref(lang)}
                label={languageDetails[lang].name}
                linkProps={{
                    className: classnames(`${cssBlock}__link`),
                }}
            />
        ));

    return (
        <NavMenu
            maxHeight={308}
            menuAriaLabel={formatter.formattedMessage({ id: 'CoreUI.languageMenuAriaLabel' })}
            buttonContents={(
                <>
                    <Icon ariaHidden iconName={isMayFourth ? 'not-a-moon' : 'globe'} />
                    {formatter?.preferences?.lang
                        && languageDetails[formatter?.preferences?.lang].name}
                </>
            )}
            buttonProps={{
                type: 'link',
                id: 'langMenuItem',
                className: classnames(`${cssBlock}__button`),
            }}
            overlayProps={{
                popperPlacement: ['bottom-end', 'top-end', 'bottom-start', 'top-start'],
            }}
        >
            <NavMenu.Group>
                {languageButtonMenuItems()}
            </NavMenu.Group>
        </NavMenu>
    );
};

LanguageDropdown.displayName = 'LanguageDropdown';

LanguageDropdown.propTypes = {
    cssBlock: PropTypes.string,
    styles: PropTypes.object,
};

export default withFormatter(LanguageDropdown);
