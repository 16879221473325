import React from 'react';
import { compose, withStylesLoaded } from '@concur/core-ui-shell';
import MiniHeader from './_MiniHeader';
import FallbackComponent from '../FallbackComponent/_FallbackComponent';
import withNuiIntl from '../utils/withNuiIntl';
import withStylesPlaceholder from '../utils/withStylesPlaceholder';

// this wrapper component is necessary to wrap the component
// in a ThemeProvider (handled in withStylesLoaded)
const AppMiniHeader = (props) => {
    const fallbackComp = (errorId) => (
        <FallbackComponent
            componentCssBlock={MiniHeader.cssBlock}
            correlationId={errorId}
        />
    );

    return (
        <MiniHeader
            {...props}
            errorComp={fallbackComp}
        />
    );
};

AppMiniHeader.displayName = 'AppMiniHeader';

AppMiniHeader.propTypes = {
    ...MiniHeader.propTypes,
};

export default compose(
    withStylesPlaceholder,
    withStylesLoaded,
    withNuiIntl,
)(AppMiniHeader);
