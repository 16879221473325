import Cookies from 'universal-cookie';
import {
    GATEWAY_BRANDING_ID,
    CONCUR_THEMES_BRANDING_ID,
    GATEWAY_HORIZON_THEMES_BRANDING_ID,
    SAP_HORIZON_THEMES_BRANDING_ID,
    SAP_QUARTZ_THEMES_BRANDING_ID,
} from '@concur/core-ui-shell/lib/constants';
import { THEME_COOKIE_NAME, THEME_SELECTOR_FORM } from '../constants';

export const getThemeCookie = () => {
    const cookies = new Cookies();
    return cookies.get(THEME_COOKIE_NAME);
};

export const saveThemeCookie = (theme) => {
    const cookies = new Cookies();

    // set cookie to expire in 10 years (cookie max)
    const date = new Date();
    date.setFullYear(date.getFullYear() + 10);
    cookies.set(THEME_COOKIE_NAME, theme, { path: '/', expires: date });
};

export const removeThemeCookie = () => {
    const cookies = new Cookies();
    cookies.remove(THEME_COOKIE_NAME, { path: '/' });
};

export const saveToSession = (brandingId, themePreviewEnabled) => {
    const form = document.getElementById(THEME_SELECTOR_FORM.FORM_ID);
    const brandingIdInput = document.getElementById(THEME_SELECTOR_FORM.BRANDING_ID_INPUT_ID);
    const previewInput = document.getElementById(THEME_SELECTOR_FORM.THEME_PREVIEW_INPUT_ID);
    if (form && brandingIdInput && previewInput) {
        brandingIdInput.value = brandingId;
        previewInput.value = themePreviewEnabled ? 'Y' : 'N';
        form.submit();
    } else {
        // eslint-disable-next-line no-console
        console.error('Unable to locate form');
    }
};

export const getShowPreviewSwitch = (brandingId, inThemePreviewMode) => {
    if (brandingId === GATEWAY_BRANDING_ID && !inThemePreviewMode) {
        return true;
    }

    if (brandingId === SAP_HORIZON_THEMES_BRANDING_ID && inThemePreviewMode) {
        return true;
    }

    return false;
};

export const getIsThemingLive = (brandingId, inThemePreviewMode) => {
    if ((brandingId === CONCUR_THEMES_BRANDING_ID
        || brandingId === SAP_QUARTZ_THEMES_BRANDING_ID
        || brandingId === GATEWAY_HORIZON_THEMES_BRANDING_ID
        || brandingId === SAP_HORIZON_THEMES_BRANDING_ID)
        && !inThemePreviewMode) {
        return true;
    }

    return false;
};

export const getBaseThemeVariants = (brandingId, inThemePreviewMode) => {
    if (brandingId === SAP_HORIZON_THEMES_BRANDING_ID
        || (brandingId === GATEWAY_BRANDING_ID && !inThemePreviewMode)) {
        return [
            'sap_horizon',
            'sap_horizon_dark',
        ];
    }

    if (brandingId === CONCUR_THEMES_BRANDING_ID && !inThemePreviewMode) {
        return [
            'concur_gateway',
            'sap_fiori_3',
            'sap_fiori_3_dark',
            'sap_horizon',
            'sap_horizon_dark',
        ];
    }

    if (brandingId === SAP_QUARTZ_THEMES_BRANDING_ID && !inThemePreviewMode) {
        return [
            'sap_fiori_3',
            'sap_fiori_3_dark',
            'sap_horizon',
            'sap_horizon_dark',
        ];
    }

    if (brandingId === GATEWAY_HORIZON_THEMES_BRANDING_ID && !inThemePreviewMode) {
        return [
            'concur_gateway',
            'sap_horizon',
            'sap_horizon_dark',
        ];
    }

    return [];
};

export const getBaseThemeVariant = (themeName) => {
    if (themeName === 'sap_fiori_3_hcb') {
        return 'sap_fiori_3_dark';
    }

    if (themeName === 'sap_fiori_3_hcw') {
        return 'sap_fiori_3';
    }

    if (themeName === 'sap_horizon_hcb') {
        return 'sap_horizon_dark';
    }

    if (themeName === 'sap_horizon_hcw' || themeName === 'concur_default') {
        return 'sap_horizon';
    }

    return themeName;
};

export const getFullThemeName = (themeVariant, isHighContrast) => {
    if (themeVariant === 'sap_fiori_3' && isHighContrast) {
        return 'sap_fiori_3_hcw';
    }

    if (themeVariant === 'sap_fiori_3_dark' && isHighContrast) {
        return 'sap_fiori_3_hcb';
    }

    if (themeVariant === 'sap_horizon' && isHighContrast) {
        return 'sap_horizon_hcw';
    }

    if (themeVariant === 'sap_horizon_dark' && isHighContrast) {
        return 'sap_horizon_hcb';
    }

    return themeVariant;
};

export const isHighContrastTheme = (themeName) => themeName?.indexOf('_hcb') > -1
    || themeName?.indexOf('_hcw') > -1;
