import classnamesBind from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import InlineError from './_InlineError';
import styles from './FallbackComponent.css';

const CSS_BLOCK = 'sapcnqr-fallback';

const FallbackComponent = (props) => {
    const {
        className,
        componentCssBlock,
        correlationId,
        useFullWidth,
        useSmallViewportBreakpoints,
    } = props;

    const classnames = classnamesBind.bind(styles);

    const classes = classnames(
        CSS_BLOCK,
        className,
        {
            [`${CSS_BLOCK}--${componentCssBlock}`]: componentCssBlock,
            [`${CSS_BLOCK}--full-width`]: useFullWidth,
            [`${CSS_BLOCK}--fully-responsive`]: useSmallViewportBreakpoints,
        },
    );

    return (
        <div className={classes}>
            <InlineError
                correlationId={correlationId}
                messageClassName={classnames(`${CSS_BLOCK}__error`)}
            />
        </div>
    );
};

FallbackComponent.displayName = 'FallbackComponent';

FallbackComponent.propTypes = {
    className: PropTypes.string,
    componentCssBlock: PropTypes.string,
    correlationId: PropTypes.string,
    useFullWidth: PropTypes.bool,
    useSmallViewportBreakpoints: PropTypes.bool,
};

export default FallbackComponent;
