import React from 'react';
import AppContainerMenuItem from './_AppContainerMenuItem';

const TranslationToolMenuItem = ({
    onLoad,
}) => (
    <AppContainerMenuItem
        fireOnContainerLoaded="ui-floorplans-ready-for-tt"
        listenForEmbeddedAppLoaded="translation-tool-loaded"
        onLoad={onLoad}
        fireOnContainerReady="translation-tool-container-added"
        containerId="translation-tool-container"
        listItemProps={{
            'data-translation-tool-container': true,
        }}
    />
);

TranslationToolMenuItem.displayName = 'TranslationToolMenuItem';

export default TranslationToolMenuItem;
