import React from 'react';

const HelpSvgIcon = (props) => (
    <svg
        {...props}
        aria-hidden
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1024 1024"
    >
        <g>
            <path d="M512 820q30 0 53-23t23-55q0-30-23-53t-53-23q-32 0-55 23t-23 53q0 32 23 55t55 23zM678 488q18-22 28-46t10-58q0-40-18-73t-46-56-65-36-75-13-75 13-66 36-47 56-18 73q0 24 17 38t41 14q50 0 50-52 0-28 26-52t76-24q52 0 78 24t26 52q0 30-16 46-6 6-18 14t-26 16-28 15-20 11q-12 10-38 29t-26 49q0 22 21 41t49 11q20-4 29-13t14-18l10-18 15-15q22-10 48-28t44-36zM512 0q106 0 199 40t163 110 110 163 40 199-40 199-110 163-163 110-199 40-199-40-163-110-110-163-40-199 40-199 110-163 163-110 199-40zM512 922q84 0 159-32t130-88 88-131 33-159-33-159-88-130-130-88-159-33-159 33-131 88-88 130-32 159 32 159 88 131 131 88 159 32z" />
        </g>
    </svg>
);

export default HelpSvgIcon;
