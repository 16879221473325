import React from 'react';
import AppContainerMenuItem from './_AppContainerMenuItem';

const HelpPortalMenuItem = ({
    onLoad,
    menuData,
}) => (
    <AppContainerMenuItem
        fireOnContainerLoaded="ui-floorplans-loaded"
        listenForEmbeddedAppLoaded="help-portal-loaded"
        onLoad={onLoad}
        fireOnContainerReady="help-portal-menu-container-added"
        appData={menuData}
        containerId="help-portal-menu"
        listItemProps={{
            'data-help-portal-menu': true,
        }}
    />
);

HelpPortalMenuItem.displayName = 'HelpPortalMenuItem';

export default HelpPortalMenuItem;
