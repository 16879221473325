import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import './_PlaceholderComponent.css';

const CSS_BLOCK = 'sapcnqr-placeholder';

const PlaceholderComponent = (props) => {
    const {
        className,
        originalComponentType,
        hasAdditionalContent,
        useFullWidth,
        useSmallViewportBreakpoints,
    } = props;

    const classes = classnames(
        CSS_BLOCK,
        className,
        {
            [`${CSS_BLOCK}--${originalComponentType}`]: originalComponentType,
            [`${CSS_BLOCK}--full-width`]: useFullWidth,
            [`${CSS_BLOCK}--fully-responsive`]: useSmallViewportBreakpoints,
        },
    );

    const additionalContentClasses = classnames(
        CSS_BLOCK,
        className,
        {
            [`${CSS_BLOCK}--additional-content`]: hasAdditionalContent,
            [`${CSS_BLOCK}--full-width`]: useFullWidth,
            [`${CSS_BLOCK}--fully-responsive`]: useSmallViewportBreakpoints,
        },
    );

    return (
        <>
            <div className={classes} />
            {hasAdditionalContent && <div className={additionalContentClasses} />}
        </>
    );
};

PlaceholderComponent.displayName = 'PlaceholderComponent';

PlaceholderComponent.propTypes = {
    className: PropTypes.string,
    hasAdditionalContent: PropTypes.bool,
    originalComponentType: PropTypes.string,
    useFullWidth: PropTypes.bool,
    useSmallViewportBreakpoints: PropTypes.bool,
};

export default PlaceholderComponent;
