import React from 'react';
import AppContainerMenuItem from './_AppContainerMenuItem';

const MessageCenterMenuItem = ({
    onLoad,
}) => (
    <AppContainerMenuItem
        fireOnContainerLoaded="ui-floorplans-loaded"
        listenForEmbeddedAppLoaded="message-center-loaded"
        onLoad={onLoad}
        fireOnContainerReady="message-center-menu-container-added"
        containerId="message-center-menu"
        listItemProps={{
            'data-message-center-menu': true,
        }}
    />
);

MessageCenterMenuItem.displayName = 'MessageCenterMenuItem';

export default MessageCenterMenuItem;
