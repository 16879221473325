import hoistNonReactStatics from 'hoist-non-react-statics';
import React from 'react';
import packageInfo from '../../package.json';
import { ERROR_CATEGORY } from '../constants';

export default (WrappedComponent) => {
    const Component = (props) => (
        <WrappedComponent
            {...props}
            errorCategory={ERROR_CATEGORY}
            version={packageInfo?.version}
        />
    );

    hoistNonReactStatics(Component, WrappedComponent);
    Component.propTypes = WrappedComponent.propTypes;
    Component.displayName = WrappedComponent.displayName || WrappedComponent.name || 'withLoggerData';

    return Component;
};
