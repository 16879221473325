import PropTypes from 'prop-types';
import React from 'react';
import useUniqueId from '@concur/nui-widgets/lib/utils/useUniqueId';

const SapLogo = (props) => {
    const {
        classnames,
        fill1ClassName,
        fill2ClassName,
        fill3ClassName,
        fill4ClassName,
        hideTitle,
        ...otherProps
    } = props;

    const idSuffix = useUniqueId();

    const gradient = (
        <linearGradient
            id="linear-gradient"
            x1="328.89"
            x2="328.89"
            y2="72"
            gradientUnits="userSpaceOnUse"
        >
            <stop offset="0" stopColor="#00b8f1" />
            <stop offset="0.02" stopColor="#01b6f0" />
            <stop offset="0.31" stopColor="#0d90d9" />
            <stop offset="0.58" stopColor="#1775c8" />
            <stop offset="0.82" stopColor="#1c65bf" />
            <stop offset="1" stopColor="#1e5fbb" />
        </linearGradient>
    );

    const content = (
        <g>
            <g>
                <path className={classnames(fill4ClassName)} d="M6.71,48.08V30H0V24.78H19.71V30H13V48.08Zm15.71-23.3h6.3v8.66h7.74V24.78h6.3v23.3h-6.3V38.66H28.72v9.42h-6.3ZM46.8,48.08V24.78H63.43V30H53.1v3.78h7.37V38.7H53.1V43H64v5.07Zm29.32,0V24.78h9.79c3.87,0,8.47.6,8.47,5.73A5,5,0,0,1,90,35.83v.06c2.83.51,5,1.86,5,5.73,0,5.07-4.72,6.46-8.65,6.46Zm6.17-14h3c2.26,0,3.11-.88,3.11-2.24s-.85-2.23-3-2.23h-3Zm0,9.29h3.15c2.36,0,3.36-.92,3.36-2.52s-1-2.43-3.43-2.43H82.29ZM98,48.08V24.78h16.63V30H104.33v3.78h7.37V38.7h-7.37V43h10.86v5.07ZM116.9,43l4.66-2.9a7.19,7.19,0,0,0,6.1,3.53c1.93,0,3.37-.57,3.37-2s-1.85-2-4.15-2.61c-3.81-1-8.88-2-8.88-7.24,0-4.76,3.59-7.37,8.88-7.37a12,12,0,0,1,9.76,4.57l-4.22,3.46a7.94,7.94,0,0,0-5.57-2.93c-1.8,0-2.71.6-2.71,1.7,0,1.54,1.82,2.11,4.12,2.68,3.81.91,8.91,2.07,8.91,7.3,0,3.71-2.61,7.4-9.79,7.4C122.06,48.52,118.88,46.13,116.9,43ZM145,48.08V30h-6.7V24.78H158V30h-6.71V48.08Zm23.78,0V24.78h9.1c6,0,9.63,2,9.63,7.59,0,3.65-1.54,5.76-4.6,6.8l5,8.91h-6.68L176.66,40h-1.73v8.09Zm6.17-12.88h2.71c2.58,0,3.71-.72,3.71-2.67s-1.13-2.65-3.71-2.65h-2.71Zm15.37,3.53V24.78h6.29V38.7c0,3.21,1,4.66,3.56,4.66s3.53-1.45,3.53-4.66V24.78h6.17V38.73c0,6.17-3,9.7-9.76,9.7S190.3,44.9,190.3,38.73Zm23.42,9.35V24.78h6.2l8.54,13.07h.06V24.78h5.76v23.3h-5.82l-8.91-13h-.07v13Z" />
                <polyline
                    className={classnames(fill1ClassName)}
                    points="256.12 72 329.68 72 401.67 0 256.12 0 256.12 72"
                />
                <path className={classnames(fill2ClassName)} d="M342.5,13.54H328.17V47.59L315.65,13.53H303.24L292.56,42c-1.14-7.19-8.57-9.67-14.42-11.53-3.85-1.24-7.95-3.06-7.91-5.07,0-1.66,2.19-3.19,6.48-3,2.89.15,5.43.38,10.49,2.82l5-8.66A38.82,38.82,0,0,0,276,12.77h0c-6.1,0-11.18,2-14.33,5.24a12.05,12.05,0,0,0-3.43,8.35,11.22,11.22,0,0,0,4.92,10,33.71,33.71,0,0,0,9.74,4.45c4,1.23,7.22,2.3,7.19,4.59a3.39,3.39,0,0,1-.95,2.24c-1,1-2.51,1.4-4.61,1.44-4.06.09-7.07-.55-11.87-3.38l-4.42,8.79a33.07,33.07,0,0,0,16.22,4.31h.74a19.62,19.62,0,0,0,12.33-4.14l.52-.45-1.43,3.84h13l2.18-6.65a23.55,23.55,0,0,0,7.65,1.22,23.93,23.93,0,0,0,7.47-1.15L319,58.06h21.22V44.3h4.63c11.19,0,17.81-5.7,17.81-15.24C362.62,18.42,356.19,13.54,342.5,13.54ZM309.38,42.69a13.11,13.11,0,0,1-4.59-.81l4.54-14.32h.09l4.46,14.36A13.39,13.39,0,0,1,309.38,42.69Zm34-8.24h-3.16V22.91h3.16c4.21,0,7.56,1.4,7.56,5.7s-3.35,5.84-7.56,5.84" />
                <path
                    className={classnames(fill3ClassName)}
                    d="M347.13,67.26a3.78,3.78,0,1,1,3.79,3.9A3.78,3.78,0,0,1,347.13,67.26Zm3.79,4.67a4.66,4.66,0,1,0-4.73-4.67A4.66,4.66,0,0,0,350.92,71.93Zm-1-4.3h.94L352.28,70h.92l-1.54-2.36a1.39,1.39,0,0,0,1.4-1.48c0-1-.63-1.52-1.89-1.52h-2.05V70h.81Zm0-.69V65.28H351c.56,0,1.16.11,1.16.78,0,.84-.61.88-1.29.88Z"
                />
            </g>
        </g>
    );

    return (
        <svg
            id={`Sap_logo_${idSuffix}`}
            data-test="Sap_logo"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 401.67 72"
            role="img"
            {...otherProps}
        >
            {!hideTitle && <title>SAP</title>}
            <defs>
                {gradient}
            </defs>
            {content}
        </svg>
    );
};

SapLogo.displayName = 'SapLogo';

SapLogo.propTypes = {
    fill1ClassName: PropTypes.string,
    fill2ClassName: PropTypes.string,
    fill3ClassName: PropTypes.string,
    fill4ClassName: PropTypes.string,
    classnames: PropTypes.any,
    hideTitle: PropTypes.bool,
};

export default SapLogo;
