import React from 'react';
import { compose, withStylesLoaded } from '@concur/core-ui-shell';
import withNuiIntl from '../utils/withNuiIntl';
import withStylesPlaceholder from '../utils/withStylesPlaceholder';
import Footer from './_Footer';
import FallbackComponent from '../FallbackComponent/_FallbackComponent';

// this wrapper component is necessary to wrap the component
// in a ThemeProvider (handled in withStylesLoaded)
const AppFooter = (props) => {
    const fallbackComp = (errorId) => (
        <FallbackComponent
            componentCssBlock={Footer.cssBlock}
            correlationId={errorId}
        />
    );

    // TODO: remove once these are removed from nui-shell and travel
    const {
        brandingId,
        stretchContent,
        themeName,
        useFullWidth,
        ...otherProps
    } = props;

    return (
        <Footer
            {...otherProps}
            errorComp={fallbackComp}
        />
    );
};

AppFooter.displayName = 'AppFooter';

AppFooter.propTypes = {
    ...Footer.propTypes,
};

export default compose(
    withStylesPlaceholder,
    withStylesLoaded,
    withNuiIntl,
)(AppFooter);
