import hoistNonReactStatics from 'hoist-non-react-statics';
import React, { useState, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import {
    getLanguage,
    getLocaleData,
    getLocaleMode,
    NuiIntlProviderLite,
} from '@concur/nui-intl-runtime';
import { isLocalizationModeEnabled, LOCALE_MODE_STEGANOGRAPHY } from '@concur/core-ui-shell';

export default (WrappedComponent) => {
    const Component = ({ testMessages, ...props }) => {
        const [messages, setMessages] = useState(testMessages);

        const name = WrappedComponent.displayName || WrappedComponent.name;
        Component.displayName = name || 'withNuiIntl';

        const localeMode = getLocaleMode(props.lang);
        const langCode = getLanguage(localeMode
            ? document.querySelector('html')?.lang
            : props.lang);

        useLayoutEffect(() => {
            let didCancel = false;
            const fetchMessagesFile = async () => {
                let messagesFile;
                try {
                    messagesFile = await import(/* webpackChunkName: "translations/ui-floorplans-[request]" */ `../translations/${langCode}`);
                } catch (e) {
                    messagesFile = await import(/* webpackChunkName: "translations/ui-floorplans-[request]" */ '../translations/en');
                }
                if (!didCancel) { // Ignore the file if the lang changes quickly
                    setMessages(messagesFile.default);
                }
            };
            if (!testMessages) {
                fetchMessagesFile();
            }
            return () => { didCancel = true; };
        }, [langCode]);

        // enable steganography if LocalizationMode cookie is set
        const nuiIntlProps = {
            localeData: {
                lang: langCode,
                locale: langCode,
                localeMode: isLocalizationModeEnabled()
                    ? LOCALE_MODE_STEGANOGRAPHY
                    : localeMode,
            },
        };

        const localeData = getLocaleData(nuiIntlProps);

        if (!messages) {
            return (
                <NuiIntlProviderLite
                    localeData={localeData}
                    customFormatters={{
                        // Use non-breaking space to retain the height of the text
                        // while the message bundle is loading
                        // eslint-disable-next-line react/no-unstable-nested-components
                        formattedMessage: () => <>&nbsp;</>,
                    }}
                >
                    <WrappedComponent {...props} />
                </NuiIntlProviderLite>
            );
        }

        return (
            <NuiIntlProviderLite messages={messages} localeData={localeData}>
                <WrappedComponent {...props} />
            </NuiIntlProviderLite>
        );
    };

    hoistNonReactStatics(Component, WrappedComponent);
    Component.propTypes = {
        lang: PropTypes.string,
        testMessages: PropTypes.object, // Only used for Jest tests
    };

    Component.defaultProps = {
        lang: 'en',
    };
    return Component;
};
