import classnamesBind from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import { withFormatter } from '@concur/nui-intl-runtime';
import { compose } from '@concur/core-ui-shell';
import styles from './InlineError.css';

const CSS_BLOCK = 'sapcnqr-inline-error';

const InlineError = (props) => {
    const {
        className,
        formatter,
        message,
        messageClassName,
    } = props;

    const classnames = classnamesBind.bind(styles);

    const classes = classnames(CSS_BLOCK, className);

    return (
        <div className={classes}>
            <div className={classnames(`${CSS_BLOCK}__error-message`, messageClassName)}>
                {message || formatter.formattedMessage({ id: 'CoreUI.errorPage_ServerError.header' })}
            </div>
        </div>
    );
};

InlineError.displayName = 'InlineError';

InlineError.propTypes = {
    className: PropTypes.string,
    // eslint-disable-next-line react/no-unused-prop-types
    correlationId: PropTypes.string,
    message: PropTypes.string,
    messageClassName: PropTypes.string,
};

export default compose(
    withFormatter,
)(InlineError);
