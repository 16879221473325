import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classnamesBind from 'classnames/bind';
import Icon from '@concur/nui-widgets/Icon';
import { withFormatter } from '@concur/nui-intl-runtime';
import AppHeaderContext from '../AppHeader/_AppHeaderContext';

const getProfileName = ({
    actingAs,
    formatter,
    employeeDisplayName,
}) => {
    const { allUsers, companyName, otherUserName } = actingAs;

    if (companyName) {
        return companyName;
    }
    if (allUsers) {
        return formatter.formattedMessage({ id: 'CoreUI.allMyUsers' });
    }
    if (otherUserName) {
        return otherUserName;
    }
    return employeeDisplayName;
};

export const getProfileDisplayText = ({
    actingAs,
    formatter,
    employeeDisplayName,
    shortNames = false,
    isFiori,
}) => {
    const { companyName } = actingAs;

    const name = getProfileName({ actingAs, formatter, employeeDisplayName });

    if (companyName) {
        return formatter.formattedMessage({ id: 'CoreUI.administeringCompany', values: { 0: companyName } });
    }

    if (isFiori) {
        return name;
    }

    return shortNames
        ? formatter.formattedMessage({ id: 'CoreUI.actingAs', values: { 0: name } })
        : formatter.formattedMessage({ id: 'CoreUI.currentlyActingAs', values: { 0: name } });
};

const ProfileIdentifier = ({
    actingAs,
    cssBlock,
    formatter,
    styles,
}) => {
    const classnames = classnamesBind.bind(styles);

    const { allUsers, companyName, otherUserName } = actingAs;
    const isActingForOthers = !!(allUsers || companyName || otherUserName);
    const { employeeDisplayName, isFiori } = useContext(AppHeaderContext) || {};

    const iconClassName = classnames(`${cssBlock}__icon`, {
        [`${cssBlock}__icon--admin`]: isActingForOthers,
        [`${cssBlock}__icon--fiori`]: isFiori,
    });

    const descriptionClassName = classnames(`${cssBlock}__description`, {
        [`${cssBlock}__description--fiori`]: isFiori,
    });

    return (
        <div className={classnames(`${cssBlock}__identifier`)} data-test="identifier">
            {(!isFiori || isActingForOthers) && (
                <Icon
                    ariaHidden
                    className={iconClassName}
                    iconName={isActingForOthers ? 'user-admin' : 'user'}
                    size="md"
                />
            )}

            <div className={classnames(`${cssBlock}__name`)}>
                {isActingForOthers
                    ? (
                        <div className={descriptionClassName}>
                            {getProfileDisplayText({
                                actingAs, formatter, employeeDisplayName, isFiori,
                            })}
                        </div>
                    )
                    : employeeDisplayName}
            </div>
        </div>
    );
};

ProfileIdentifier.displayName = 'ProfileIdentifier';

ProfileIdentifier.propTypes = {
    actingAs: PropTypes.shape({
        allUsers: PropTypes.bool,
        companyName: PropTypes.string,
        otherUserName: PropTypes.string,
    }),
    cssBlock: PropTypes.string,
    styles: PropTypes.object,
};

export default withFormatter(ProfileIdentifier);
