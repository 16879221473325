import React from 'react';
import { compose, withStylesLoaded } from '@concur/core-ui-shell';
import withNuiIntl from '../utils/withNuiIntl';
import withStylesPlaceholder from '../utils/withStylesPlaceholder';
import ErrorImpl, { ERRORS } from './_Error';
import FallbackComponent from '../FallbackComponent/_FallbackComponent';

export { ERRORS };

// this wrapper component is necessary to wrap the component
// in a ThemeProvider (handled in withStylesLoaded)
const AppError = (props) => {
    const fallbackComp = (errorId) => (
        <FallbackComponent
            componentCssBlock={ErrorImpl.cssBlock}
            correlationId={errorId}
        />
    );

    return (
        <ErrorImpl
            {...props}
            errorComp={fallbackComp}
        />
    );
};

AppError.displayName = 'AppError';

AppError.propTypes = {
    ...ErrorImpl.propTypes,
};

export default compose(
    withStylesPlaceholder,
    withStylesLoaded,
    withNuiIntl,
)(AppError);
