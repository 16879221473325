import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnamesBind from 'classnames/bind';
import Icon from '@concur/nui-widgets/Icon';
import List from '@concur/nui-widgets/List';
import isEnterOrSpace from '@concur/nui-widgets/lib/utils/isEnterOrSpace';
import { withFormatter } from '@concur/nui-intl-runtime';
import { withErrorBoundary, compose } from '@concur/core-ui-shell';
import ProfileIdentifier from './_ProfileIdentifier';
import ActingAsFiori from './_ActingAsFiori';
import SignOutSvgIcon from './_SignOutSvgIcon';
import userProfileStyles from './UserProfileMenu.css';

const CSS_BLOCK = 'sapcnqr-user-profile-menu';

const UserProfileMenuFiori = ({
    actingAs,
    baseUrl,
    formatter,
    isGov,
    onClose,
    styles,
    onEndLoading,
    onStartLoading,
    profileUrl,
    actingAsQuickHelpRef,
    actingAsUserDropdownRef,
}) => {
    const classnames = classnamesBind.bind({ ...styles, ...userProfileStyles });

    const { allUsers, companyName, otherUserName } = actingAs;
    const isActingForOthers = !!(allUsers || companyName || otherUserName);

    const [actingAsExpanded, setIsActingAsExpanded] = useState(isActingForOthers);

    const isProfileDisabled = !profileUrl || profileUrl.trim() === '#';

    const onLogOut = () => {
        if (baseUrl) {
            window.location.href = `${baseUrl}/nui-auth/api/signout?signedout=manual`;
        } else {
            window.location.href = '/errors/loggedout.asp';
        }
    };

    return (
        <div
            className={classnames(CSS_BLOCK)}
            data-test="user-profile-menu"
        >
            <ProfileIdentifier
                actingAs={actingAs}
                cssBlock={CSS_BLOCK}
                styles={{ ...styles, ...userProfileStyles }}
            />
            <List
                aria-label={formatter.formattedMessage({ id: 'CoreUI.profile.profileMenu' })}
                className={classnames(`${CSS_BLOCK}__list`)}
            >
                {(actingAs.options?.length > 0 || isActingForOthers) && (
                    <List.Item
                        aria-label={!actingAsExpanded ? formatter.formattedMessage({ id: 'CoreUI.actingAsExpand' })
                            : formatter.formattedMessage({ id: 'CoreUI.actingAsCollapse' })}
                        aria-expanded={actingAsExpanded}
                        data-test="user-profile-menu-acting-as"
                        className={classnames(`${CSS_BLOCK}__list-item`)}
                    >
                        <span
                            className={classnames(`${CSS_BLOCK}__list-item-content`)}
                            onClick={() => {
                                setIsActingAsExpanded(!actingAsExpanded);
                            }}
                            onKeyDown={(event) => {
                                if (isEnterOrSpace(event)) {
                                    setIsActingAsExpanded(!actingAsExpanded);
                                }
                            }}
                            role="button"
                            tabIndex={0}
                        >
                            <Icon
                                ariaHidden
                                className={classnames(`${CSS_BLOCK}__icon`)}
                                iconName="shuffle"
                                size="md"
                            />
                            {formatter.formattedMessage({ id: 'CoreUI.actAsAnotherUser' })}
                            <Icon
                                ariaHidden
                                className={classnames(`${CSS_BLOCK}__acting-as-trigger-icon`)}
                                iconName={actingAsExpanded ? 'arrow-3-n' : 'arrow-3-s'}
                                size="sm"
                            />
                        </span>
                        {actingAsExpanded && (
                            <div className={classnames(`${CSS_BLOCK}__acting-as`, `${CSS_BLOCK}__acting-as--fiori`)}>
                                <ActingAsFiori
                                    isActingForOthers={isActingForOthers}
                                    isGov={isGov}
                                    onClose={onClose}
                                    onEndLoading={onEndLoading}
                                    onStartLoading={onStartLoading}
                                    options={actingAs.options}
                                    quickHelpRef={actingAsQuickHelpRef}
                                    userDropdownRef={actingAsUserDropdownRef}
                                />
                            </div>
                        )}
                    </List.Item>
                )}
                <List.Item
                    data-test="user-profile-menu-profile-link"
                    className={classnames(`${CSS_BLOCK}__list-item`)}
                >
                    <a
                        aria-disabled={isProfileDisabled}
                        className={classnames(`${CSS_BLOCK}__list-item-content`, {
                            [`${CSS_BLOCK}__list-item-content--disabled`]: isProfileDisabled,
                        })}
                        href={profileUrl !== '#' ? profileUrl : undefined}
                        onKeyDown={() => {}}
                        role="menuitem"
                        tabIndex={isProfileDisabled ? -1 : 0}
                    >
                        <Icon ariaHidden className={classnames(`${CSS_BLOCK}__icon`)} iconName="gear" size="md" />
                        {formatter.formattedMessage({ id: 'CoreUI.profile.profileSettings' })}
                    </a>
                </List.Item>
                <List.Item
                    data-test="user-profile-menu-signout-link"
                    className={classnames(`${CSS_BLOCK}__list-item`)}
                >
                    <span
                        className={classnames(`${CSS_BLOCK}__list-item-content`)}
                        onClick={onLogOut}
                        onKeyDown={(event) => {
                            if (isEnterOrSpace(event)) {
                                onLogOut();
                            }
                        }}
                        role="button"
                        tabIndex={0}
                    >
                        <SignOutSvgIcon
                            className={classnames(`${CSS_BLOCK}__list-item-content-svg`)}
                            pathClassName={classnames(`${CSS_BLOCK}__list-item-content-path`)}
                        />
                        {formatter.formattedMessage({ id: 'CoreUI.signOut' })}
                    </span>
                </List.Item>
            </List>
        </div>
    );
};

UserProfileMenuFiori.displayName = 'UserProfileMenuFiori';

UserProfileMenuFiori.cssBlock = CSS_BLOCK;

UserProfileMenuFiori.propTypes = {
    actingAs: PropTypes.shape({
        allUsers: PropTypes.bool,
        companyName: PropTypes.string,
        options: PropTypes.arrayOf(PropTypes.string),
        otherUserName: PropTypes.string,
    }),
    baseUrl: PropTypes.string,
    isGov: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    onEndLoading: PropTypes.func,
    onStartLoading: PropTypes.func,
    profileUrl: PropTypes.string,
    actingAsQuickHelpRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.object,
    ]),
    actingAsUserDropdownRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.object,
    ]),
    styles: PropTypes.object,
};

UserProfileMenuFiori.defaultProps = {
    actingAs: {},
    profileUrl: '#',
};

export default compose(
    withFormatter,
    withErrorBoundary,
)(UserProfileMenuFiori);
