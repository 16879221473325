import { useEffect } from 'react';
import { TRANSLATION_TOOL_URL_IDENTIFIER } from '../constants';

const useModifiedHelpMenu = ({ menuData, translationToolLoaded, helpPortalLoaded }) => {
    let reDispatchTimer;
    useEffect(() => {
        if (translationToolLoaded || helpPortalLoaded) {
            const detail = {
                ...menuData,
                helpMenuItem: {
                    ...menuData?.helpMenuItem,
                    subItems: menuData?.helpMenuItem?.subItems?.filter(
                        ({ url }) => !(url?.indexOf(TRANSLATION_TOOL_URL_IDENTIFIER) >= 0
                            && translationToolLoaded),
                    ) || [],
                },
            };
            // ensure the re-dispatch is fired after the original dispatch which has a timeout of 0
            reDispatchTimer = setTimeout(
                () => window.dispatchEvent(new CustomEvent('help-portal-menu-container-added', { detail })),
                10,
            );
        }
        return () => {
            clearTimeout(reDispatchTimer);
        };
    }, [helpPortalLoaded, translationToolLoaded]);
};

export default useModifiedHelpMenu;
